import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  const [date, setDate] = useState('');
  const [labName, setLabName] = useState('');
  const [orders, setOrders] = useState([{ dentistName: '', delivery: false, pickup: false }]);
  const [comment, setComment] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [sendMethod, setSendMethod] = useState('whatsapp');

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleAddOrder = () => {
    setOrders([...orders, { dentistName: '', delivery: false, pickup: false }]);
  };

  const handleRemoveOrder = (index) => {
    const newOrders = orders.filter((_, i) => i !== index);
    setOrders(newOrders);
  };

  const handleOrderChange = (index, field, value) => {
    const newOrders = [...orders];
    newOrders[index][field] = value;
    setOrders(newOrders);
  };

  const generateWhatsAppLink = () => {
    const phoneNumber = "33649292905";
    const deliveries = orders.filter(order => order.delivery).length;
    const pickups = orders.filter(order => order.pickup).length;
    const totalOrders = deliveries + pickups;

    let message = `Commandes ${date}`;
    if (labName) message += ` - Laboratoire: ${labName}`;
    message += ':\n\n';
    orders.forEach((order, index) => {
      if (order.dentistName || order.delivery || order.pickup) {
        message += `${order.dentistName || 'Sans nom'}: ${order.delivery ? 'L' : ''}${order.pickup ? 'R' : ''}\n`;
      }
    });
    message += `\nL:${deliveries} R:${pickups} T:${totalOrders}`;
    if (comment) {
      message += `\nNote: ${comment}`;
    }

    const encodedMessage = encodeURIComponent(message);
    return `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
  };

  const generateSMSLink = () => {
    const phoneNumber = "33649292905";
    let message = `Commandes ${date}`;
    if (labName) message += ` - Laboratoire: ${labName}`;
    message += ':\n\n';
    orders.forEach((order, index) => {
      if (order.dentistName || order.delivery || order.pickup) {
        message += `${order.dentistName || 'Sans nom'}: ${order.delivery ? 'L' : ''}${order.pickup ? 'R' : ''}\n`;
      }
    });
    const deliveries = orders.filter(order => order.delivery).length;
    const pickups = orders.filter(order => order.pickup).length;
    const totalOrders = deliveries + pickups;
    message += `\nL:${deliveries} R:${pickups} T:${totalOrders}`;
    if (comment) {
      message += `\nNote: ${comment}`;
    }

    const encodedMessage = encodeURIComponent(message);
    return `sms:${phoneNumber}?body=${encodedMessage}`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let link = sendMethod === 'whatsapp' ? generateWhatsAppLink() : generateSMSLink();
    window.location.href = link;
  };

  const styles = {
    container: {
      maxWidth: '100%',
      margin: '0 auto',
      padding: '10px',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f8f9fa',
      color: '#333',
    },
    header: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    title: {
      color: '#d32f2f',
      fontSize: isMobile ? '2.5rem' : '3.5rem',
      fontWeight: 'bold',
    },
    link: {
      color: '#1976d2',
      textDecoration: 'none',
      marginLeft: '10px',
      fontSize: isMobile ? '0.8rem' : '1rem',
    },
    form: {
      border: '1px solid #d32f2f',
      padding: '15px',
      borderRadius: '8px',
      backgroundColor: '#fff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    input: {
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: isMobile ? '16px' : '14px',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#d32f2f',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1rem',
      marginTop: '10px',
      transition: 'background-color 0.3s',
    },
    addButton: {
      backgroundColor: '#4caf50',
      marginBottom: '10px',
    },
    removeButton: {
      backgroundColor: '#f44336',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      fontSize: '0.8rem',
      marginLeft: '10px',
    },
    orderItem: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '5px',
      marginBottom: '10px',
      alignItems: isMobile ? 'flex-start' : 'center',
    },
    checkboxContainer: {
      display: 'flex',
      gap: '10px',
      marginTop: isMobile ? '5px' : '0',
    },
    warningMessage: {
      fontSize: '0.9rem',
      color: '#721c24',
      backgroundColor: '#f8d7da',
      border: '1px solid #f5c6cb',
      borderRadius: '4px',
      padding: '10px',
      marginBottom: '15px',
      textAlign: 'center',
      lineHeight: '1.4',
    },
    whatsappInfo: {
      fontSize: '0.9rem',
      color: '#4a4a4a',
      textAlign: 'center',
      marginTop: '15px',
      lineHeight: '1.4',
      padding: '10px',
      border: '1px solid #d32f2f',
      borderRadius: '4px',
      backgroundColor: '#fff5f5',
    },
    whatsappIcon: {
      width: '20px',
      height: '20px',
      verticalAlign: 'middle',
      marginRight: '5px',
    },
    instructions: {
      fontSize: '0.8rem',
      color: '#666',
      textAlign: 'left',
      marginTop: '20px',
      lineHeight: '1.4',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      backgroundColor: '#f9f9f9',
    },
    select: {
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: isMobile ? '16px' : '14px',
    },
    infoMessage: {
      fontSize: '0.9rem',
      color: '#4a4a4a',
      textAlign: 'center',
      marginTop: '10px',
      fontStyle: 'italic',
    },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>TransportsMarcel</h1>
        <p style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <a href="https://www.transportsmarcel.fr" target="_blank" rel="noopener noreferrer" style={styles.link}>www.transportsmarcel.fr</a>
        </p>
      </header>

      <div style={styles.warningMessage}>
        * Ce service est destiné uniquement aux prothésistes dentaires. Pour tout autre service, veuillez nous contacter via le formulaire de notre site web officiel.
      </div>

      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          style={styles.input}
        />
        <input
          type="text"
          placeholder="Nom du laboratoire (optionnel)"
          value={labName}
          onChange={(e) => setLabName(e.target.value)}
          style={styles.input}
        />
        {orders.map((order, index) => (
          <div key={index} style={styles.orderItem}>
            <input
              type="text"
              placeholder="Nom du dentiste"
              value={order.dentistName}
              onChange={(e) => handleOrderChange(index, 'dentistName', e.target.value)}
              style={styles.input}
            />
            <div style={styles.checkboxContainer}>
              <label>
                <input
                  type="checkbox"
                  checked={order.delivery}
                  onChange={(e) => handleOrderChange(index, 'delivery', e.target.checked)}
                />
                Livraison
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={order.pickup}
                  onChange={(e) => handleOrderChange(index, 'pickup', e.target.checked)}
                />
                Retour
              </label>
            </div>
            <button type="button" onClick={() => handleRemoveOrder(index)} style={styles.removeButton}>
              Supprimer
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddOrder} style={{ ...styles.button, ...styles.addButton }}>
          + Ajouter un dentiste
        </button>
        <textarea
          placeholder="Commentaire (optionnel)"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ ...styles.input, minHeight: '80px' }}
        />
        <select 
          value={sendMethod} 
          onChange={(e) => setSendMethod(e.target.value)}
          style={styles.select}
        >
          <option value="whatsapp">WhatsApp</option>
          <option value="sms">SMS</option>
        </select>
        {sendMethod !== 'whatsapp' && (
          <p style={styles.infoMessage}>
            Pour un meilleur traitement, nous recommandons l'envoi via WhatsApp.
          </p>
        )}
        <button type="submit" style={styles.button}>
          Envoyer les commandes
        </button>
      </form>

      <div style={styles.whatsappInfo}>
        <img 
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z' fill='%2325D366'/%3E%3C/svg%3E"
          alt="WhatsApp Icon"
          style={styles.whatsappIcon}
        />
        Cliquez sur "Envoyer les commandes" pour ouvrir l'application choisie et envoyer le résumé.
        <br />
        WhatsApp est recommandé pour une communication rapide et efficace !
      </div>

<div style={styles.instructions}>
        * Fonctionnement : 1. Entrez la date. 2. Saisissez le nom de votre laboratoire. 3. Ajoutez le nom du client et sélectionnez livraison ou retour (ou les deux). 4. Pour un client supplémentaire, cliquez sur "Ajouter un dentiste" et procédez comme précédemment. 5. Choisissez la méthode d'envoi. 6. Cliquez sur "Envoyer les commandes".
        <br /><br />
        ** Pour ajouter l'application sur l'écran de votre téléphone :
        <br />
        - iPhone : Ouvrez le site dans Safari, appuyez sur l'icône de partage (carré avec une flèche vers le haut), puis sur "Sur l'écran d'accueil".
        <br />
        - Android : Ouvrez le site dans Chrome, appuyez sur les trois points en haut à droite, puis sur "Ajouter à l'écran d'accueil".
      </div>
    </div>
  );
};

export default Dashboard;
